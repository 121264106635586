import {useTranslation} from 'react-i18next';
import {DownOutlined} from '@ant-design/icons';
import type {MenuProps} from 'antd';
import {Button, Dropdown} from 'antd';
import cn from 'classnames'
import {GB, GR, IT} from 'country-flag-icons/react/3x2'

import cls from './Header.module.scss'

export const SelectLanguage = () => {

    const {t, i18n} = useTranslation()

    let current = <></>
    switch (i18n.language) {
        case 'en':
            current = <GB className={cn([cls.flag, cls.flagOnButton])}/>;
            break;
        case 'it':
            current = <IT className={cn([cls.flag, cls.flagOnButton])}/>;
            break;
        case 'el':
            current = <GR className={cn([cls.flag, cls.flagOnButton])}/>;
            break;
    }

    const items: MenuProps['items'] = [
        {
            key: 'it',
            label: t('Italian'),
            icon: <IT className={cls.flag}/>,
            onClick: () => i18n.changeLanguage('it'),
        },
        {
            key: 'en',
            label: t('English'),
            icon: <GB className={cls.flag}/>,
            onClick: () => i18n.changeLanguage('en'),
        },
        {
            key: 'el',
            label: t('Greek'),
            icon: <GR className={cls.flag}/>,
            onClick: () => i18n.changeLanguage('el'),
        },
    ]

    return (
        <Dropdown menu={{items}}>
            <Button type={'text'}>{current} <DownOutlined/></Button>
        </Dropdown>
    )
}