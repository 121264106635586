import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useNavigate} from 'react-router-dom';
import {MenuOutlined} from '@ant-design/icons';
import type {MenuProps} from 'antd';
import {Button, Dropdown} from 'antd';
import cn from 'classnames';
import _ from 'lodash'
import {ABOUT, CONTACTS, HEROES, HOME, PARTNER, PASS, SUPPORT, VEHICLES} from 'routes/routes';

import logo from './moow-logo.svg'
import { SelectLanguage } from './SelectLanguage';

import cls from './Header.module.scss'

function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

export const Header = () => {

    const {t} = useTranslation()
    const navigate = useNavigate()

    const menuItems = [
        // {
        //     link: HOME,
        //     label: t('Home'),
        // },
        {
            link: ABOUT,
            label: t('About'),
        },
        {
            link: VEHICLES,
            label: t('Vehicles'),
        },
        {
            link: HEROES,
            label: t('Heroes'),
        },
        {
            link: PARTNER,
            label: t('Become a partner'),
        },
        {
            link: PASS,
            label: t('Pass'),
        },
        {
            link: SUPPORT,
            label: t('Support'),
        },
        {
            link: CONTACTS,
            label: t('Contacts'),
        },
    ]

    const [width, setWidth] = useState(getWindowDimensions().width);
    useEffect(() => {
        function handleResize() {
            setWidth(getWindowDimensions().width);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const items: MenuProps['items'] = _.map(menuItems, item => ({
        key: item.link,
        label: (
            <Link to={item.link}>{item.label}</Link>
        ),
    }))

    return (
        <div className={cls.header}>
            <div className={cn(['col12', cls.wrapper])}>
                <div className={cls.left}>
                    <SelectLanguage/>
                </div>
                <div className={cls.center}>
                    <Link to={HOME}>
                        <img className={cls.logo} src={logo} alt={'Logo MOOW'}/>
                    </Link>
                </div>
                <div className={cls.right}>
                    {width > 1024
                        ?
                        _.map(menuItems, item => (
                            <Button type={'text'} key={item.link}
                                    onClick={() => navigate(item.link)}>{item.label}</Button>
                        ))
                        :
                        <Dropdown menu={{items}}>
                            <Button type={'text'} icon={<MenuOutlined/>}/>
                        </Dropdown>
                    }
                </div>
            </div>
        </div>
    )
}