import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
// @ts-ignore
import TopLoader from 'react-top-loader';
import { RightOutlined} from '@ant-design/icons';
import {useQuery} from '@apollo/client';
import { Space} from 'antd';
import {Block} from 'blocks/Block/Block';
import {Blocks} from 'blocks/Blocks/Blocks';
import {Chess} from 'blocks/Chess/Chess';
import {ISquare} from 'blocks/Chess/Square';
import {Cover} from 'blocks/Cover/Cover';
import {IHighlight} from 'blocks/Highlights/Highlight';
import {Highlights} from 'blocks/Highlights/Highlights';
import {Image} from 'blocks/Image/Image';
import {Title} from 'blocks/Title/Title';
import cn from 'classnames';
import dayjs from 'dayjs';
import {home,homeVariables} from 'graphql/__generated__/home';
import {HOME} from 'graphql/home';
import _ from 'lodash';
import {observer} from 'mobx-react-lite';
import {APP_AppStore, APP_GooglePlay} from 'routes/routes';

import {YellowButton} from 'components/YellowButton/YellowButton';

import {App} from '../../blocks/App/App';
import {News} from '../../blocks/News/News';
import {INewsCard} from '../../blocks/News/NewsCard';
import {AdaptiveModal} from '../../components/AdaptiveModal/AdaptiveModal';
import {getMobileOperatingSystem} from '../../components/detectUserAgent';
import {imageUrl} from '../../components/imageUrl';
import {news, newsVariables} from '../../graphql/__generated__/news';
import {QUERY_NEWS} from '../../graphql/news';

import img_app_icon from './img/app_icon.png'
import img_cover_bg from './img/cover_bg.png';
import img_appstore_it from './img/Download_on_the_App_Store_Badge_IT_RGB_blk_100317.svg'
import img_appstore_en from './img/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';
import img_playmarket_en from './img/google-play-badge-EN.png'
import img_playmarket_it from './img/google-play-badge-IT.png';
import img_cover_desktop from  './img/img_cat_desktop.png';
import img_cover_mobile from  './img/img_cat_mobile.png';
import img_school_scooter from './img/img_school_scooter.png';
import img_school_bg from './img/school_bg.png';

import cls from './Home.module.scss'

function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

export const Home = observer(() => {

    const {t, i18n} = useTranslation()
    const [searchParams] = useSearchParams();

    const initPopupOpen = searchParams.has('no') || getMobileOperatingSystem() === 'iOS' || getMobileOperatingSystem() === 'Android'
    const [popupOpen, setPopupOpen] = useState<boolean>(initPopupOpen)

    const [loadingHome, setLoadingHome] = useState<boolean>(true)
    const [loadingNews, setLoadingNews] = useState<boolean>(true)

    const home = useQuery<home,homeVariables>(HOME, {
        variables: {
            locale: i18n.language
        },
        onCompleted: () => {setLoadingHome(false)},
    })
    const news = useQuery<news,newsVariables>(QUERY_NEWS, {
        variables: {
            locale: i18n.language,
            pagination: {limit: 4},
            sort: ['PublishAt:DESC'],
        },
        onCompleted: () => {setLoadingNews(false)},
    })

    // console.log('loadingHome', loadingHome)
    // console.log('loadingNews', loadingNews)

    const [width, setWidth] = useState(getWindowDimensions().width);
    useEffect(() => {
        function handleResize() {
            setWidth(getWindowDimensions().width);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const highlights: IHighlight[] = _.map(home.data?.home?.data?.attributes?.RuleCards, item => ({
        image: imageUrl(item?.Image?.data?.attributes?.url!),
        title: item?.Title!,
        content: item?.Description!,
    }))

    const chess: ISquare[] = _.map(home.data?.home?.data?.attributes?.SafetyChess, item => ({
        image: imageUrl(item?.Image?.data?.attributes?.url!),
        list: _.map(item?.List, list => ({
            icon: imageUrl(list?.Icon?.data?.attributes?.url!),
            text: list?.Text!
        }))
    }))

    const newsData: INewsCard[] = _.map(news.data?.news?.data, item => ({
        title: item.attributes?.Title!,
        description: item.attributes?.Description!,
        cover: item.attributes?.Cover?.data?.attributes?.url!,
        date: dayjs(item.attributes?.PublishAt),
    }))

    const AppStoreBadge = i18n.language === 'it'
        ?
        <a href={APP_AppStore} target={'_blank'} rel={'noreferrer'}><img height={40} className={cls.badge}
                                                                         src={img_appstore_it} alt={'AppStore Badge'}/></a>
        :
        <a href={APP_AppStore} target={'_blank'} rel={'noreferrer'}><img height={40} className={cls.badge}
                                                                         src={img_appstore_en} alt={'AppStore Badge'}/></a>

    const PlayMarketBadge = i18n.language === 'it'
        ?
        <a href={APP_GooglePlay} target={'_blank'} rel={'noreferrer'}><img height={40} className={cls.badge}
                                                                           src={img_playmarket_it}
                                                                           alt={'PlayMarket Badge'}/></a>
        :
        <a href={APP_GooglePlay} target={'_blank'} rel={'noreferrer'}><img height={40} className={cls.badge}
                                                                           src={img_playmarket_en}
                                                                           alt={'PlayMarket Badge'}/></a>

    if (searchParams.has('no')) {
        console.log('Есть код')
        console.log('getMobileOperatingSystem', getMobileOperatingSystem())
    }

    let appText = getMobileOperatingSystem() === 'Android' ? home.data?.home?.data?.attributes?.AppAndroidText! : home.data?.home?.data?.attributes?.AppIOSText!
    const appLink = getMobileOperatingSystem() === 'Android' ? APP_GooglePlay : APP_AppStore

    return (
        <div>

            <TopLoader show={loadingHome && loadingNews} color="#FF40B4"/>
            
            <AdaptiveModal open={popupOpen} onClose={() => setPopupOpen(false)}>
                <Block>
                    <Title>{home.data?.home?.data?.attributes?.AppTitle!}</Title>
                    <App name={'MOOW'} description={appText} icon={img_app_icon}/>
                    <a href={appLink} target={'_blank'} rel={'noreferrer'} className={'block'}>
                        <YellowButton size={'large'} block>{home.data?.home?.data?.attributes?.AppButton!} <RightOutlined/></YellowButton>
                    </a>
                </Block>
            </AdaptiveModal>

            <Cover
                title={home.data?.home?.data?.attributes?.CoverTitle!}
                footer={
                    <Space>
                        {AppStoreBadge}
                        {PlayMarketBadge}
                    </Space>
                }
                bg={img_cover_bg}
                image={width > 768 ? img_cover_desktop : img_cover_mobile}
            />

            <Blocks>

                <Block>
                    <Title>{home.data?.home?.data?.attributes?.RuleTitle!}</Title>
                    <Highlights items={highlights} className={'col12'}/>
                </Block>

                <Block>
                    <Image src={imageUrl(home.data?.home?.data?.attributes?.Image?.data?.attributes?.url!)} toll cover/>
                </Block>

                <Block>
                    <Title>{home.data?.home?.data?.attributes?.SafetyTitle!}</Title>
                    <Chess items={chess} className={'col8'}/>
                </Block>

                {newsData.length >= 2 &&
                <Block>
                    <Title>{t('Latest News')}</Title>
                    <News items={newsData} className={'col12'}/>
                </Block>
                }

            </Blocks>

            <div className={cls.school} style={{backgroundImage: `url(${img_school_bg})`}}>
                <div className={cls.wrapper}>
                    <Title className={'col8'}>{home.data?.home?.data?.attributes?.SchoolTitle!}</Title>
                    <Image src={img_school_scooter} className={'col8'}/>
                    {/*<YellowButton size={'large'} className={'col4'}>{home.data?.home?.data?.attributes?.SchoolButton!} <RightOutlined/></YellowButton>*/}
                    <YellowButton size={'large'} className={'col4'} disabled>{home.data?.home?.data?.attributes?.SchoolButton!} <RightOutlined/></YellowButton>
                    <div className={cn(['col12', cls.line])}/>
                </div>
            </div>

        </div>
    )
})