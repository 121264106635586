import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
// @ts-ignore
import TopLoader from 'react-top-loader';
import {MailOutlined, PhoneOutlined} from '@ant-design/icons';
import { useQuery} from '@apollo/client';
import {BlocksRenderer} from '@strapi/blocks-react-renderer';
import { Block } from 'blocks/Block/Block';
import { Blocks } from 'blocks/Blocks/Blocks';
import { Solid } from 'blocks/Solids/Solid';
import cn from 'classnames';
import _ from 'lodash';

import Flag from 'components/Flag/Flag';

import {SmallCover} from '../../blocks/SmallCover/SmallCover';
import {Title} from '../../blocks/Title/Title';
import {contacts, contactsVariables} from '../../graphql/__generated__/contacts';
import {QUERY_CONTACTS} from '../../graphql/contacts';

import cls from './Contacts.module.scss'

export const Contacts = () => {

    const {i18n} = useTranslation()
    const [loading, setLoading] = useState<boolean>(true)

    const contacts = useQuery<contacts, contactsVariables>(QUERY_CONTACTS, {
        variables: {
            locale: i18n.language
        },
        onCompleted: () => {
            setLoading(false)
        }
    })

    return (
        <>

            <TopLoader show={loading} color="#FF40B4"/>

            <SmallCover
                title={contacts.data?.contacts?.data?.attributes?.title!}
            />

            <Blocks>

                <Block>
                    <div className={'col8 StrapiRichBlocks'}>
                        <BlocksRenderer content={contacts.data?.contacts?.data?.attributes?.mainText || []} />
                    </div>
                </Block>

                <Block>
                    <Title className={'col8'}>{contacts.data?.contacts?.data?.attributes?.blockTitle}</Title>
                    <div className={cn(cls.blocks, 'col12')}>
                        {_.map(contacts.data?.contacts?.data?.attributes?.blocks, (block, index) => (
                            <Solid key={index} icon={<Flag className={cls.flag} code={block?.code!}/>} title={block?.country!} className={cn(cls.card, 'col4')}>
                                <div className={cls.contacts}>
                                    <div className={'bold'}>{block?.name}</div>
                                    {block?.address && <div>{block?.address}</div>}
                                    {block?.phone && <div><PhoneOutlined/> <a href={`tel:${block?.phone}`}>{block?.phone}</a></div>}
                                    {block?.email && <div><MailOutlined/> <a href={`mailto:${block.email}`}>{block?.email}</a></div>}
                                </div>
                            </Solid>
                        ))}
                    </div>
                </Block>

            </Blocks>

        </>
    )
}