import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import {type BlocksContent, BlocksRenderer} from '@strapi/blocks-react-renderer';
import {Button, Form, Input} from 'antd';
import PhoneInput from 'antd-phone-input';
import {Block} from 'blocks/Block/Block';
import {Blocks} from 'blocks/Blocks/Blocks';
import {Text} from 'blocks/Text/Text';
import {Title} from 'blocks/Title/Title';
import {createDeleteAccount, createDeleteAccountVariables} from 'graphql/__generated__/createDeleteAccount';
import {privacyPolicy, privacyPolicyVariables} from 'graphql/__generated__/privacyPolicy';
import {CREATE_DELETE_ACCOUNT} from 'graphql/delete-account';
import {PRIVACYPOLICY} from 'graphql/privacy-policy';
import {SUCCESS_DELETE_ACCOUNT} from 'routes/routes';

import cls from '../Partner/Partner.module.scss';


export const PrivacyPolicy = () => {

    const {t, i18n} = useTranslation()
    const navigate = useNavigate()

    const [formCaption, setFormCaption] = useState<BlocksContent>([])
    const [content, setContent] = useState<BlocksContent>([])

    const [createDeleteAccount, createDeleteAccountData] = useMutation<createDeleteAccount, createDeleteAccountVariables>(CREATE_DELETE_ACCOUNT)

    const privacyPolicy = useQuery<privacyPolicy, privacyPolicyVariables>(PRIVACYPOLICY, {
        variables: {
            locale: i18n.language
        },
        onCompleted: (data) => {
            setFormCaption(data.privacyPolicy?.data?.attributes?.FormCaption! || [])
            setContent(data.privacyPolicy?.data?.attributes?.Content! || [])
        }
    })

    const onFinish = async (data: any) => {
        await createDeleteAccount({
            variables: {
                data: {
                    phone: `+${data.phone.countryCode} (${data.phone.areaCode}) ${data.phone.phoneNumber}`,
                    email: data.email,
                }
            },
            onCompleted: () => {
                navigate(SUCCESS_DELETE_ACCOUNT)
            }
        })
    }

    // @ts-ignore
    const validator = (_, {valid}) => {
        // if (valid(true)) return Promise.resolve(); // strict validation
        if (valid(true)) return Promise.resolve(); // non-strict validation
        return Promise.reject(t('Invalid format'));
    }

    return (
        <div>

            <Blocks>

                <Block>
                    <Title>{privacyPolicy.data?.privacyPolicy?.data?.attributes?.FormTitle!}</Title>
                    <Text>{privacyPolicy.data?.privacyPolicy?.data?.attributes?.FormSubtitle!}</Text>
                    <Form rootClassName={'col4'} layout={'vertical'} className={cls.form} size={'large'}
                          onFinish={onFinish}>
                        <Form.Item name={'phone'} label={t('Phone')}
                                   rules={[
                                       {required: true, message: `${t('Please fill the field')}`},
                                       {validator},
                                   ]}
                        >
                            <PhoneInput enableSearch preferredCountries={['it']} country={'it'}/>
                        </Form.Item>
                        <Form.Item name={'email'} label={t('Email')}
                                   rules={[
                                       {required: true, message: `${t('Please fill the field')}`},
                                       {type: 'email', message: `${t('Please fill the field')}`},
                                   ]}
                        >
                            <Input placeholder={'name@example.com'}/>
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType={'submit'} type={'primary'} block
                                    loading={createDeleteAccountData.loading}>{privacyPolicy.data?.privacyPolicy?.data?.attributes?.FormButton!}</Button>
                        </Form.Item>
                    </Form>
                    <div className={'col8 StrapiRichBlocks caption'}>
                        <BlocksRenderer content={formCaption}/>
                    </div>
                </Block>

                <Block>
                    <Title>{privacyPolicy.data?.privacyPolicy?.data?.attributes?.Title!}</Title>
                    <div className={'col8 StrapiRichBlocks'}>
                        <BlocksRenderer content={content}/>
                    </div>
                </Block>


            </Blocks>


        </div>
    )
}