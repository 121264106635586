import {gql} from '@apollo/client';

export const QUERY_CONTACTS= gql`
    query contacts($locale: I18NLocaleCode) {
        contacts(locale: $locale) {
            data {
                attributes {
                    title
                    mainText
                    blockTitle
                    blocks {
                        country
                        code
                        name
                        address
                        phone
                        email
                    }
                }
            }
            
        }
    }
`;