import React, {ReactNode} from 'react';
import cn from 'classnames'

import cls from './Solids.module.scss'

export interface ISolid {
    icon?: ReactNode
    title: string
    content?: string
    className?: string
    children?: ReactNode
}

export const Solid = ({icon, title, content, children, className}:ISolid) => (
    <div className={cn([cls.solid, className])}>
        <div className={cls.title}>
            {icon}
            {title}
        </div>
        <div>
            {content}
            {children}
        </div>
    </div>
)