import { hasFlag } from 'country-flag-icons';
import * as Flags from 'country-flag-icons/react/3x2';

interface IFlag {
    code: string
    className?: string
}

const Flag = ({ code, className } : IFlag) => {

    if (hasFlag(code)) {
        // eslint-disable-next-line import/namespace
        const FlagComponent = Flags[code as keyof typeof Flags]
        return <FlagComponent className={className}/>
    } else {
        return null
    }

};

export default Flag;