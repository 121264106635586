import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import {COOKIE_POlICY, PRIVACY_POLICY, TERMINI_POLICY } from 'routes/routes'

import Facebook from './social/Facebook.svg'
import Instagram from './social/Instagram.svg'
import Likee from './social/Likee.svg'
import Telegram from './social/Telegram.svg'
import TikTok from './social/TikTok.svg'
import YouTube from './social/YouTube.svg'

import cls from './Footer.module.scss'

export const Footer = () => {

    const {t} = useTranslation()

    const socialItems = [
        {
            img: Instagram,
            link: 'https://www.instagram.com/moow.rent/',
        },
        {
            img: TikTok,
            link: 'https://www.tiktok.com/@moow.rent',
        },
        {
            img: YouTube,
            link: 'https://www.youtube.com/@MOOWRENT',
        },
        {
            img: Likee,
            link: 'https://l.likee.video/moow.rent',
        },
        {
            img: Facebook,
            link: 'https://facebook.com/moow.rent',
        },
        {
            img: Telegram,
            link: 'https://t.me/moow_help_bot',
        },
    ]

    const menuItems = [
        {
            link: TERMINI_POLICY,
            label: t('Terms and Conditions'),
        },
        {
            link: PRIVACY_POLICY,
            label: t('Privacy Policy'),
        },
        {
            link: COOKIE_POlICY,
            label: t('Cookie Policy'),
        },
    ]

    return (
        <div className={cls.footer}>
            <div className={cls.social}>
                {_.map(socialItems, (item, index) => (
                    <a key={index} href={item.link} target={'_blank'} rel={'noreferrer'}>
                        <img src={item.img} alt={'Icon'}/>
                    </a>
                ))}

            </div>
            <div className={cls.links}>
                {_.map(menuItems, (item) => (
                    <Link key={item.link} to={item.link}>{item.label}</Link>
                ))}
            </div>
            <div className={cls.caption}>
                <div>
                    {'© 2024 RENTech L.L.C-FZ.'}
                </div>
                <div>
                    {'© 2024 AZIENDA NAZIONALE DI MICROMOBILITA\' S.R.L.'}
                </div>
            </div>
        </div>
    )
}